// // import { useParams } from "react-router-dom";
// // import Footer from "../Components/Footer/Footer";
// // import Navbar from "../Components/Navbar/Navbar";

// // // Define the type for the winner
// // type Winner = {
// //   id: number;
// //   name: string;
// //   title: string;
// //   movie: string;
// //   year: number;
// //   imageDir: string;
// //   link?: string; // Optional link
// // };

// // interface WinnersProps {
// //   winnersList: Winner[]; // Specify that winnersList is an array of Winner type
// // }

// // const Winners = ({ winnersList }: WinnersProps) => {
// //   const { year } = useParams<{ year: string }>(); // Get year as a string from route parameters
// //   const filteredWinners = winnersList.filter(
// //     (winner) => winner.year === parseInt(year || "0", 10) // Ensure default value if year is undefined
// //   );

// //   return (
// //     <section className="bg-black">
// //       <Navbar />
// //       <main className="flex flex-col items-center py-20 bg-black">
// //         <h1 className="text-light-gold text-5xl uppercase font-bold mb-10">
// //           Winners of {year}
// //         </h1>
// //         <div className="px-6 sm:px-10 lg:px-40">
// //           <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
// //             {filteredWinners.map((winner: Winner) => (
// //               <div
// //                 key={winner.id}
// //                 className="winner-item bg-black p-6 rounded-lg shadow-lg hover:bg-[#1d1d1d] transition max-w-sm mx-auto h-auto shadow-none hover:shadow-lg hover:shadow-gray-400"
// //               >
// //                 <div className="flex flex-col items-center mb-4">
// //                   {/* Winner Image */}
// //                   <img
// //                     src={winner.imageDir || "/path/to/default-image.jpg"}  // Add fallback image path here
// //                     alt={winner.name}
// //                     loading="lazy"
// //                     className="w-32 h-32 rounded-full mb-4 object-cover"
// //                   />
// //                   <h2 className="text-2xl font-bold text-light-gold mb-2">{winner.name}</h2>
// //                   <p className="text-lg font-bold text-white">{winner.title}</p>
// //                   <p className="text-md text-white font-semibold">{winner.movie}</p>
// //                 </div>
// //                 {winner.link && (
// //                   <div className="text-center mt-4">
// //                     <a
// //                       href={winner.link}
// //                       target="_blank"
// //                       rel="noopener noreferrer"
// //                       className="text-light-gold text-xl font-semibold hover:text-gold-400"
// //                     >
// //                       Visit IMDb Profile
// //                     </a>
// //                   </div>
// //                 )}
// //               </div>
// //             ))}
// //           </div>
// //         </div>
// //       </main>
// //       <Footer />
// //     </section>
// //   );
// // };

// // export default Winners;

// import React from 'react';
// import Timeline from '../Components/Winners/Timeline';

// const Winners: React.FC = () => {
//   return (
//     <div>
//       <h1 className="text-center text-4xl font-bold my-10">Winners Timeline</h1>
//       <Timeline />
//     </div>
//   );
// };

// export default Winners;







import Footer from "../Components/Footer/Footer";
import { winnersList } from "../Data/winners";
import Navbar from "../Components/Navbar/Navbar";
import { lazy, Suspense } from "react";
import { Link } from "react-router-dom";

const WinnerSection = lazy(() => import("../Components/Winners/WinnerSection"));

const Winners = () => {
    const sortedWinners = winnersList.sort((a, b) => a.id - b.id); // Sort winners by id
    
    return (
        <section className="bg-black">
            <Navbar />
            <main className="flex flex-col items-center py-20 bg-black">
                <h1 className="text-light-gold hover:shadow-lg hover:shadow-light-gold rounded-lg p-5 text-center max-[250px]:text-2xl max-[350px]:text-4xl text-5xl uppercase font-bold mb-10">
                    Winners 
                </h1>
                <div className="px-6 sm:px-10 lg:px-40">
                    {/* Grid Layout for Winners */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
                        {sortedWinners.map((winner) => (
                            <Suspense fallback={<div>Loading...</div>} key={winner.id}>
                                <div className="winner-item bg-black p-6 rounded-lg shadow-lg hover:bg-[#1d1d1d] transition rounded-md max-w-sm mx-auto h-auto shadow-none transition-shadow duration-300 cursor-pointer hover:shadow-lg hover:shadow-gray-400">
                                    
                                    <div className="flex flex-col items-center mb-4">
                                        {/* Winner Image */}
                                        <img
                                            src={winner.imageDir}  // Fallback image if needed
                                            alt={winner.name}
                                            loading="lazy"
                                            className="w-32 h-32 rounded-full mb-4 object-cover"
                                        />
                                        {/* Winner Name */}
                                        <Link to={`/biography/${winner.id}`} className="text-light-gold hover:text-gold">
                                            <h2 className="text-2xl font-bold text-light-gold mb-2">{winner.name}</h2>
                                        </Link>
                                        <p className="text-lg font-bold text-white">{winner.title}</p>
                                        <p className="text-md text-white font-semibold">{winner.movie}</p>
                                    </div>

                                    {/* IMDb Link */}
                                    {winner.link && (
                                        <div className="text-center mt-4">
                                            <a
                                                href={winner.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="inline-block text-xl font-semibold text-light-gold hover:text-gold-400 transition"
                                            >
                                                Visit IMDb Profile
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </Suspense>
                        ))}
                    </div>
                </div>
            </main>
            <Footer />
        </section>
    );
};

export default Winners;
