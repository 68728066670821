import React from 'react';
// import Header from './Header'; //currently not using this header anymore
// import '../CSS/styles.css'
import Footer from '../Components/Footer/Footer';
import Navbar from "../Components/Navbar/Navbar";

import { aboutImage, aboutImage1, aboutImage2 } from '../Data/export_images';
import {motion} from "motion/react";
// import aboutImage from '../images/IFFFAabout.jpeg'; 
// import aboutImage1 from '../images/IFFAaboutbottompage1.jpeg'; 
// import aboutImage2 from '../images/IFFAaboutbottompage2.jpeg'; 


function AboutUs() {
    return (
        <div className="min-h-screen flex flex-col bg-black">
            <Navbar />
            
            <motion.main
                initial={{ opacity: 0, x:-150 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1.5 }}
                className="flex-grow flex flex-col items-center"
            >
                <div className="flex flex-col items-center bg-black w-full px-20 pb-12 pt-5">
                    <h1 className='text-light-gold text-5xl font-bold mb-10'>Our Purpose</h1>
                    <p className='text-white font-semibold max-xl:text-xl xl:text-2xl leading-7 w-4/5 max-lg:w-11/12 text-justify'>
                        {/* The International Film Festival of Australia (IFFA) is dedicated to promoting the captivating world of cinema. With a burning passion for both local and international talent, our mission is twofold: to propel Australian films and creations onto the global stage and to bring the captivating world of cinema to the shores of Australia.
                        At IFFA we aim to recognize and honor exceptional artists, directors, producers, and the commendable works of feature films, short films, and documentaries from around the globe. Our primary objective is to shine a resplendent spotlight on the unheralded artists who have been the driving force behind the magnificence of their craft. At IFFA, we believe that art transcends boundaries and has the extraordinary ability to bridge gaps between cultures, nations, and communities.
                        Join us on this extraordinary adventure of a cinematic odyssey that traverses continents, unites cultures, and celebrates the power of storytelling. If you aspire to be a part of this event, we invite you to submit your work and get nominated for IFFA. Whether you're a budding filmmaker, an experienced documentarian, or a visionary digital creator, IFFA welcomes your talent and creativity. Join us in celebrating the magic of cinema by submitting your masterpiece! */}
                        The International Film Festival of Australia (IFFA) celebrates the art of cinema by showcasing local and international talent. Its mission is to elevate Australian films globally and bring world cinema to Australia. IFFA honors exceptional artists, directors, and creators of feature films, short films, and documentaries, with a focus on highlighting unheralded talent. Believing in the power of art to bridge cultures and communities, IFFA invites filmmakers and creators to submit their work and join a cinematic journey that unites and inspires through storytelling.
                    </p>

                </div>
                <img src={aboutImage} alt="aboutImage" loading="lazy" className='rounded-xl shadow-[rgba(255,255,255,0.25)_0px_25px_50px_-12px]' />
                
            </motion.main>
            <Footer />
        </div>
    );
}

export default AboutUs;