import Footer from "../Components/Footer/Footer";
import {
    animationNominationsWithIds,
    documentaryNominationsWithIds,
    featureFilmNominationsWithIds,
    shortFilmNominationsWithIds,
    under18NominationsWithIds
} from "../Data/films";
import NominationSection from "../Components/Nominations/NominationSection";
// import HeroSection from "../Components/Home/HeroSection/HeroSection";
// import { DriveTrailer } from "../Data/export_videos";
import Navbar from "../Components/Navbar/Navbar";


const Nominations = () => {
    return (
        <section className="bg-black">
            <Navbar />
            {/* <HeroSection videoBackground={DriveTrailer}/> */}
            <main className="flex flex-col items-center my-20 text-white">
                <h1 className="text-white text-center max-[250px]:text-2xl max-[350px]:text-4xl text-5xl uppercase font-bold">Nominations 2024</h1>
                <div className="flex flex-col max-md:mt-10 mt-20 gap-y-20 max-sm:px-0 px-20">
                    <NominationSection title="Feature Films" nominationList={featureFilmNominationsWithIds} />
                    <NominationSection title="Animations" nominationList={animationNominationsWithIds} />
                    <NominationSection title="Documentary" nominationList={documentaryNominationsWithIds} />
                    <NominationSection title="Short Films" nominationList={shortFilmNominationsWithIds} />
                    <NominationSection title="Under 18s" nominationList={under18NominationsWithIds} />
                </div>    
            </main>
            <Footer />
        </section>
    );
};

export default Nominations;