import { motion, useMotionValue } from "motion/react";
import { useState, FC, useEffect, useRef } from "react";
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
} from "react-icons/bs";
import { Film } from "../../../Data/films";
import FilmCardCarousel from "./FilmCardCarousel";

interface CarouselProps {
  films: Film[];
  slides_count: number;
}

const FilmCardContainer: FC<{ films: Film[] }> = ({ films }) => (
  <>
    {films.map((film) => (
      <FilmCardCarousel key={film.name} film={film} />
    ))}
  </>
);

const Slides: FC<{
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  films: Film[];
  dots_count: number;
  setDotsCount: React.Dispatch<React.SetStateAction<number>>;
}> = ({ current, setCurrent, films, dots_count, setDotsCount }) => {
  const DRAG_BUFFER = 50;
  const slidesRef = useRef<HTMLDivElement>(null);
  const dragX = useMotionValue(0);

  const FILM_CARD_WIDTH_LARGE = 300;
  const FILM_CARD_WIDTH_SMALL = 200;
  const GAP_X = 40;
  const GAP_X_INITIAL = 20;

  const [film_card_width, setFilmCardWidth] = useState<number>(FILM_CARD_WIDTH_LARGE);
  const [films_per_slide, setFilmsPerSlide] = useState<number>(1);

  const calculateSlides = () => {
    if (slidesRef.current) {
      const slidesWidthTotal = slidesRef.current.offsetWidth;
      const films_per_slide_temp = Math.floor(slidesWidthTotal / film_card_width);
      const gapTotal = GAP_X_INITIAL + GAP_X * films_per_slide_temp;
      const slidesWidthActual = slidesWidthTotal - gapTotal;
      const films_per_slide_actual = Math.floor(slidesWidthActual / film_card_width) || 1;

      setFilmsPerSlide(films_per_slide_actual);
      setDotsCount(Math.ceil(films.length / films_per_slide_actual));
    }
  };

  const handleResize = () => {
    const width = window.innerWidth;
  
    // Dynamically adjust film card width based on screen size
    if (width > 1200) {
      setFilmCardWidth(FILM_CARD_WIDTH_LARGE); // Large screens
    } else if (width > 768) {
      setFilmCardWidth(250); // Tablets
    } else if (width > 480) {
      setFilmCardWidth(180); // Small tablets
    } else {
      setFilmCardWidth(150); // Phones
    }
  
    // Recalculate slides
    calculateSlides();
  };
  
  useEffect(() => {
    // Set initial values and add event listener
    handleResize();
    window.addEventListener("resize", handleResize);
  
    // Cleanup on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  

  const onDragEnd = () => {
    const x = dragX.get();
    if (x <= -DRAG_BUFFER && current < dots_count - 1) {
      setCurrent((prev) => prev + 1);
    } else if (x >= DRAG_BUFFER && current > 0) {
      setCurrent((prev) => prev - 1);
    }
  };

  return (
    <div ref={slidesRef} className="overflow-hidden flex flex-col justify-center relative bg-black rounded-xl shadow-white shadow-md">
      <motion.div
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        animate={{
          translateX: `${-1 * (current * films_per_slide * (film_card_width + GAP_X) + GAP_X_INITIAL)}px`,
        }}
        className="flex pl-12 py-10 gap-x-10 transition ease-out duration-40"
        onDragEnd={onDragEnd}
        style={{ x: dragX }}
      >
        <FilmCardContainer films={films} />
      </motion.div>
    </div>
  );
};

const Dots: FC<{
  films: Film[];
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  current: number;
  dots_count: number;
}> = ({ setCurrent, current, dots_count }) => {
  const dots_count_array = Array.from({ length: dots_count });

  return (
    <div className="py-20 flex flex-wrap justify-center gap-3 w-full">
      {dots_count_array.map((_, i) => (
        <div
          key={`circle-${i}`}
          onClick={() => setCurrent(i)}
          className={`rounded-full w-5 h-5 cursor-pointer ${
            i === current ? "bg-white" : "bg-gray-500"
          }`}
        ></div>
      ))}
    </div>
  );
};

const CarouselMoviesTest: FC<CarouselProps> = ({ films, slides_count }) => {
  const [current, setCurrent] = useState<number>(0);
  const [dots_count, setDotsCount] = useState<number>(0);

  const previousSlide = () => setCurrent((prev) => (prev === 0 ? dots_count - 1 : prev - 1));
  const nextSlide = () => setCurrent((prev) => (prev === dots_count - 1 ? 0 : prev + 1));

  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-x-10 px-10 w-[100vw] h-[550px]">
        <button onClick={previousSlide}>
          <BsFillArrowLeftCircleFill className="w-10 h-10" />
        </button>
        <Slides current={current} setCurrent={setCurrent} films={films} dots_count={dots_count} setDotsCount={setDotsCount} />
        <button onClick={nextSlide}>
          <BsFillArrowRightCircleFill className="w-10 h-10" />
        </button>
      </div>
      <Dots films={films} setCurrent={setCurrent} current={current} dots_count={dots_count} />
    </div>
  );
};

export default CarouselMoviesTest;
