import {
    CabriniActressCristiana,
    ClownDirector,
    DecodedActorDanielWu,
    EchoActressPriyankaNomula,
    ForceOfNatureDirector_RobertConnolly,
    JoyOfArtDirector,
    MelodyBG,
    MelodyDirector,
    MirrorActorJoelPierce,
    MonkeymanDirector,
    PapaAurSmartphoneDirector,
    RobotDirector,
    TheMurderDirector_AlexDymalovski,
    TheNoteDirector,
    TheWanderingIslandDirector,
    TheWanderingIslandDirector_PabloBorges,
    UnsungHeroActor_JJPantano,
    missingImage,
} from "../Data/export_images";

export type Winner = {
    id: number;
    name: string;
    title: string;
    movie: string;
    imageDir: string;
    year: number;
    biography: string;
    link?: string;
    additionalLinks?: { label: string; url: string }[];
}

export const winnersList: Winner[] = [
    {
        id: 9,
        name: "Joel Pierce",
        movie: "Mirror Mirror",
        title: "Best Short Film  Actor",
        imageDir: `${MirrorActorJoelPierce}`,
        year: 2024,
        biography: `Joel Pierce: A Life of Resilience and Passion
        Born: April 27, 1980 | Hometown: Vryheid, KwaZulu Natal

        Joel Pierce entered the world on a sunny winter morning, three weeks late and with the umbilical cord wrapped around his neck—a dramatic start to a life filled with challenges and triumphs. Over the next 13 years, he welcomed two brothers and a sister, forming the foundation of his close-knit family.
      
        Joel’s childhood was marked by frequent moves, attending five schools before settling at Lowveld High. Despite these transitions, Joel thrived. 
        He:
        Earned a scholarship to study petrochemical science and communication.
        Became head boy in his final year.
        Won the prestigious Victor Ludorum for excelling in both academics and athletics.
        Participated in rugby, cricket, and horse riding—balancing both mind and body.
        Joel’s modeling career began in 1996, culminating in the title of Mr. Lowveld in 1997—a precursor to his love for the arts.

        In 2000, Joel completed his BA at the University of Pretoria, all while cultivating his passion for acting. He starred in plays like Murder on the Nile and The Crucible. During this period, Joel also:

        Hosted an African TV and radio program.
        Was scouted for the popular soap opera Egoli, acting in the show until 2004.
        His role in Egoli brought him national recognition, including public appearances across South Africa. One of his highlights was gracing Bluebull billboards in Pretoria, declaring proudly, “I am a Bluebull,” much to the delight of his mother, a rival team supporter.

        In 2004, Joel emigrated to Australia, seeking new opportunities. His adventurous spirit led him to backpack across the country in 2007, taking on odd jobs like fruit picking—a quintessential Australian experience, as he fondly recalls.

        A year later, Joel began teaching chess, a role he enjoyed before transitioning into accounting, dabbling in the stock market, and ultimately becoming a buyer and advisor for Cutter & Buck Australia, an international golf company.

        Beyond his professional achievements, Joel takes immense pride in being a father to two amazing daughters from his first partner. Throughout his life, he has remained deeply connected to his passion for acting, establishing a name for himself in the Australian entertainment industry.

        Joel’s journey is a testament to his resilience, adaptability, and unwavering pursuit of his passions. From a small-town boy in South Africa to a respected actor and advisor in Australia, Joel continues to embrace life’s challenges with grace and determination.

        `, 
        link: "https://www.imdb.com/name/nm1517974/",
        additionalLinks: [
            {
              label: "Joel Pierce Website",
              url: "https://joelpierceactor.wordpress.com/joel-the-boy/"
            },
            {
              label: "Joel Pierce story",
              url: "https://www.creatingartists.com.au/our-story/"
            }
          ]


    },{
        id: 10,
        name: "Priyanka Nomula",
        movie: "Echo",
        title: "Best Short Film Actress",
        imageDir: `${EchoActressPriyankaNomula}`,
        year: 2024,
        biography: `Priyanka Nomula – Actress, Model, Creator, and Advocate

        Priyanka Nomula is a talented and passionate entertainer, combining creativity, storytelling, and a drive to make a difference. As an actress, model, creator, and entrepreneur, she has established herself as a multifaceted talent, captivating audiences with her authentic performances and creative vision.
        
        Priyanka’s acting journey is marked by several notable projects that have resonated deeply with audiences. 
        
        She delivered a standout performance in Aadya, a heartfelt telugu short film that won the Best Film Award at the TAAI Melbourne Short Film Festival. This sweet yet tragic love story highlighted Priyanka’s ability to portray complex emotions with depth and sensitivity. 
        
        Another noteworthy project, Arera Manasa, short film that went viral explored the raw dynamics of a final conversation between two ex-lovers, where Priyanka’s portrayal raised poignant questions about closure and rekindled feelings.
        
        Her portfolio also includes lighter, relatable narratives like Chupulu Kalisina Shubavela, a hilarious Telugu short film that humorously captures the disasters and surprises a girl encounters in her dating journey. 

        Priyanka’s work extends to the music videos, where she starred in original music videos such as Oh My Baby by GK Nesan and Maikam by Palan, further showcasing her ability to connect with diverse audiences.
        
        Priyanka is now gearing up for her first feature film, Agrajeeta, directed by the celebrated Sandeep Raj. This ambitious project promises to tackle deeply rooted social stigmas, reflecting Priyanka’s dedication to meaningful and thought-provoking storytelling.
        
        One of her most recent and celebrated roles is in the short film Echo. Priyanka plays Priya, an Indian woman married to an Australian, navigating the challenges of cultural differences that strain her marriage. Her performance reflects Priya’s journey to finding harmony by embracing both worlds. Priyanka also contributed as the dialogue writer for Echo, adding depth and authenticity to the film’s narrative.
        
        Beyond her work on screen, Priyanka is the creative mind behind Brown Queen Diaries, a podcast series that amplifies the voices of South Asian women. Through this platform, Priyanka dives into topics like cultural identity, personal growth, and health awareness, including critical conversations such as equality and equity. Featuring inspiring guests, Brown Queen Diaries has become a beacon of empowerment and connection for women navigating unique challenges and triumphs.
        
        As a passionate entrepreneur, Priyanka founded Aussie Talkies in partnership with Sandeep Raj, a media agency focused on creating impactful content that bridges cultures and resonates globally to produce compelling stories that celebrate diversity and human connection.
        
        Priyanka’s achievements are deeply inspired by her strong support system, which includes her loving family and close friends. They provide her with the motivation to push boundaries and pursue her dreams relentlessly.
        
        With a career that spans award-winning performances, thought-provoking storytelling, and empowering creative ventures, Priyanka Nomula continues to redefine success. Whether she’s on screen, behind the microphone, or shaping impactful narratives as an entrepreneur, Priyanka remains committed to uplifting and inspiring others.`, 
        link: "https://www.instagram.com/priyankanomulaofficial/"
    }, {
        id: 11,
        name: "Raj Sisodiaa",
        movie: "Papa Aur Smartphone",
        title: "Best Direction",
        imageDir: `${PapaAurSmartphoneDirector}`,
        year: 2024,
        biography: `Cinema reflects every nuance of life, capturing moments from the sweetness of morning tea to the depth of a heartfelt story. With a career spanning eight years, Raj Sisodiaa has immersed himself in this vibrant industry, honing his craft as an Assistant Director. His journey is driven by practical learning and hands-on experience, mastering areas such as filmmaking, production processes, research, budgeting, and film analysis.

        Raj is currently working on exciting new projects, striving to bring meaningful and compelling stories to the screen.

        Raj Sisodiaa’s extensive experience in the film industry spans across roles such as First Assistant Director, Script Supervisor, and Second Unit Director. He has worked on notable projects like the web series 36 Days with BBC Studios and Sutliyan for Manor Rama Pictures, ensuring seamless production and creative alignment. Raj contributed to feature films such as Bhuj: The Pride of India under T-Series and Ajay Devgn Films, where he managed shooting schedules and on-set operations. As a Second Unit Director, he helmed action sequences for The Stoneman Murders, and as Chief Assistant Director, he supported productions like Bhaagte Raho. Early in his career, he worked as an Additional Assistant Director for films like Tever and Jigariyaa, showcasing his versatility and dedication to large-scale productions. This diverse experience reflects his deep expertise in filmmaking, story development, and team coordination, solidifying his reputation in the entertainment industry.

        With a deep passion for cinema and storytelling, Raj Sisodiaa continues to bring impactful narratives to life, always pushing boundaries and exploring new creative horizons.`,
        link: "https://www.imdb.com/name/nm9738207/",
        additionalLinks: [
            {
              label: "Raj Sisodiaa LinkedIn",
              url: "https://www.linkedin.com/in/raj-sisodiaa-7a9577134"
            }
          ]
    }, 
    {
        id: 12,
        name: "Pablo Borges",
        movie: "The Wandering Island",
        title: "Best International Short Film",
        imageDir: `${TheWanderingIslandDirector_PabloBorges}`,
        year: 2024,
        biography:`
        Pablo Borges is a filmmaker and photojournalist based in Las Palmas de Gran Canaria, originally from Santa Cruz de Tenerife. Through his work, Pablo explores new narratives and creative languages, capturing the essence of island life and shedding light on often-overlooked aspects of his surroundings. His photography and filmmaking focus on authentic storytelling, reflecting the unique identity of his environment.

        Pablo Borges Díaz-Llanos has built an extensive career as a filmmaker and photojournalist, with a diverse range of roles across multiple creative fields. He is currently working as a producer at Gloomy Kids Films, where he focuses on freelance production, social media marketing, and photography. As a self-employed photographer since 2019, he has undertaken independent projects that showcase his ability to capture compelling visuals. His filmmaking career, which began in 2017, has seen him direct, produce, and edit numerous projects, honing his skills in video editing, scriptwriting, and social media marketing. Additionally, he contributed as a writer and reporter for Revista Hidalgos from 2016 to 2018, where his articles focused on engaging and thought-provoking online journalism.

  Notable Projects
  1. “La Isla Errante” (The Wandering Island)
        A critically acclaimed film that won Pablo the Best Young Canarian Artist 2023 Award from the Fundación La Caja de Canarias. The film explores the lives of a grandfather and grandson navigating the challenges of their struggling banana plantation amidst the looming threat of commercialization.

  2. Short Film Portfolio
     Directed over 12 short films, including:
        “La Divina Tragedia” – Winner of Best Direction at the 6th Indian World Film Festival in Hyderabad and a finalist at the Kinorex Film Festival in Moscow.
        “La Magua del Ausente” – Winner of Best Fiction Short at the Nieves Lugo Festival and the IFO Film Festival in Florida, an official selection at San Rafael en Corto 2022, and a finalist at Korterraza 2022. The film is currently available online with over 11,000 views.

        With his award-winning work and passion for uncovering authentic stories, Pablo Borges continues to make a significant impact in both the cinematic and journalistic realms.`
        ,
        link: "https://www.imdb.com/name/nm16295305/",
        additionalLinks: [
            {
              label: "Pablo Borges LinkedIn",
              url: "https://www.linkedin.com/in/pabloborges9/?trk=opento_nprofile_details"
            },
            {
              label: "Pablo Borges portfolio",
              url: "https://merlinka.com/portfolio-artist/pablo-borges-diaz-llanos/"
            }
          ]
    }, 
    {
        id: 13,
        name: "Chris Beadnell",
        movie: "The Note",
        title: "Best Short Film Screenplay",
        imageDir: `${TheNoteDirector}`,
        year: 2024,
        biography: `Chris Beadnell: Filmmaker, Writer, Director, and Producer

        Chris Beadnell is a passionate filmmaker who discovered his love for scriptwriting at the age of 48. Over time, he refined his skills, earning numerous international accolades for his screenplays. In 2023, Chris achieved a milestone when his first film was produced, igniting a deeper passion for filmmaking and a desire to explore roles in producing and directing.

        Motivated by this new ambition, Chris pursued formal studies and conducted extensive self-research. He went on to write, co-produce, and direct his debut film, The Note, collaborating with a talented cast and crew of 27.

        Chris has been writing stories for as long as he can remember, always driven by an innate desire to share the narratives in his mind. His scripts often focus on emotional vulnerability, whether expressed through comedy, drama, or mystery.

        As he continues to immerse himself in the film industry, Chris remains committed to writing, producing, directing, and collaborating with other creatives to bring meaningful stories to life.`,
        link: "https://www.imdb.com/name/nm14571258/",

        additionalLinks: [
            {
              label: "Chris Beadnell YouTube",
              url: "https://www.youtube.com/channel/UCjlsc9imoqLYQ-tiCOmLkpQ/about"
            },
            {
              label: "Chris Beadnell profiles",
              url: "https://www.scriptrevolution.com/profiles/chris-beadnell"
            }
          ]



    }, {
        id: 18,
        name: "Alex Dymalovski",
        movie: "The Murder",
        title: "Best Student Movie",
        imageDir: `${TheMurderDirector_AlexDymalovski}`,
        year: 2024,
        biography: "Winner of the 2024 IFFA Best Director award, Joel started his career with over 400 episodes on the drama series Egoli. Following that he has continued to work on a number of local and international projects over the years. One of his favourites has been working with Dylan O'Brien on the Oscar nominated film 'Love & Monsters' by Paramount Pictures. Recent credits include working opposite Bryan Brown on Darby & Joan and he will be seen next on an Apple TV series with more details to be released at a later date.",
        link: "https://www.imdb.com/name/nm14511177/",
        additionalLinks: [
            {
              label: "Alex Dymalovski Website",
              url: "https://www.starnow.com/u/alexanderjamesd/"
            },
            {
              label: "Alex Dymalovski News",
              url: "https://www.youngaustralianfilmfestival.com.au/the-team"
            }
          ]


    }, {
        id: 1,
        name: "Sunny Deol",
        movie: "Gadar2",
        title: "Best Actor for Feature Films",
        imageDir: `${missingImage}`,
        year: 2024,
        biography: `Sunny Deol is a renowned Indian actor who began his career with his father’s production, Betaab (1983), directed by Rahul Rawail. The film was a commercial success and launched his successful acting career. He followed this with prominent roles in Sohni Mahiwal (1984), Arjun (1985), and Paap Ki Duniya (1988).

        In 1989, Deol gained further attention for his portrayal of a police officer in Tridev and his role in ChaalBaaz, both of which became some of the highest-grossing films of that year. In 1990, he played the lead role of a boxer in Ghayal, another production by his father, directed by Rajkumar Santoshi. The film was a massive hit and earned him the Filmfare Award for Best Actor and the National Film Special Jury Award.

        In 1993, Deol collaborated with Santoshi once again in Damini, portraying an alcoholic lawyer, which earned him both the Filmfare and National Film Award for Best Supporting Actor. That same year, he was nominated for the Filmfare Award for Best Actor for his performance in Darr, where he played a lieutenant army officer.

        In 1996, Deol played a criminal in Jeet and worked with Santoshi once more in Ghatak. In 1999, he expanded his career by producing, directing, and starring in Dillagi, although the film didn’t achieve commercial success. However, in 2001, he delivered a blockbuster hit with Gadar: Ek Prem Katha, a patriotic drama set during the India-Pakistan partition of 1947. Co-starring Amisha Patel, the film became the highest-grossing Hindi film at the time and earned Deol another Filmfare Award nomination for Best Actor.

        Deol continued to be a major presence in Bollywood with his roles in Indian (2001), Apne (2007), and the Yamla Pagla Deewana series (2011–2018). In 2016, he directed and starred in a sequel to Ghayal. In 2019, he directed and launched his elder son’s acting career with the romantic thriller Pal Pal Dil Ke Paas.`,
        link: "https://www.imdb.com/name/nm0219971/",
        additionalLinks: [
            {
              label: "Sunny Deol wikipedia",
              url: "https://en.wikipedia.org/wiki/Sunny_Deol_filmography"
            }
          ]


    },{
        id: 2,
        name: "Cristiana Dell’Anna",
        movie: "Cabrini",
        title: "Best Actress for Feature Films",
        imageDir: `${CabriniActressCristiana}`,
        year: 2024,
        biography: `Cristiana Dell’Anna was born in Naples, Italy, to Lidia Albero, a school teacher, and Ettore Dell’Anna, a surgeon. The second of three children, she was initially expected to follow her father’s path and pursue a career in medicine. However, her passion for acting led her to chase her dreams, prompting her move to London at age 20 to study drama at the prestigious Drama Studio London (DSL).

        Fluent in both Italian and English since the age of 10, Dell’Anna began her acting career in London. She made her stage debut in an English adaptation of the Italian play A Woman Alone, a fringe production that garnered praise from Time Out London. While still a student, she was cast as the lead in Moths, the award-winning short film directed by Rose Glass (Saint Maud). Before returning to Italy to join the cast of HBO Max’s acclaimed series Gomorrah, she also appeared in Third Contact, a psychological thriller that premiered at the BFI IMAX in 2013.
        
        Dell’Anna’s portrayal of Patrizia in Gomorrah, now streaming on Amazon Prime in the US and Canada, brought her international recognition. The series has been sold in 190 countries, further cementing her global appeal.
        
        In 2021, she starred as Luisa De Filippo opposite Toni Servillo in Mario Martone’s The King of Laughter. Her performance as a vulnerable woman in love with a man who refused to legally recognize their children earned her a nomination for the David di Donatello Award for Best Supporting Actress. She also won the Ciak d’Oro award in 2022.
        
        Dell’Anna’s film credits include Paolo Sorrentino’s Oscar-nominated The Hand of God. Though her role was initially written for an older actress, Sorrentino cast her instead, adjusting the schedule to depict her character’s aging with precision. In 2022, she starred as the lead in Netflix’s Toscana, which became the platform’s most-watched film worldwide for two consecutive weeks.
        
        Recently, renowned casting director Denise Chamian selected Dell’Anna for the lead role in the US independent feature Cabrini. In this film, she portrays an Italian nun fighting for equal rights in 19th-century New York. Starring alongside John Lithgow and David Morse, the movie explores themes of female empowerment, immigration, and assimilation.
        
        Upcoming projects include The Panic, directed by Dan Adams, where Dell’Anna stars as Bella Da Costa Greene, J.P. Morgan’s personal librarian, alongside Cary Elwes and Colm Meaney. She will also appear in the Italian Netflix series Storia della mia Famiglia, written by Filippo Gravino and Elisa Dondi and directed by Claudio Cupellini. The series follows a man’s journey on the last day of his life.`,
        link: "https://www.imdb.com/name/nm3822030/",
        additionalLinks: [
            {
              label: "Cristiana Dell’Anna wikipedia",
              url: "https://en.wikipedia.org/wiki/Cristiana_Dell%27Anna"
            }
          ]
    }, {
        id: 4,
        name: "Daniel Wu",
        movie: "Decoded",
        title: "Best Supporting Actor for Feature Films",
        imageDir: `${DecodedActorDanielWu}`,
        year: 2024,
        biography: `Daniel Wu is a celebrated Hong Kong-American actor, director, and producer whose career spans over 25 years. Renowned for his versatility, Wu has made significant contributions to the Asian film industry and gained international acclaim for his work in action, martial arts, and dramatic films, showcasing both his acting prowess and physical skills.

        Wu’s entertainment career began in the late 1990s after moving to Hong Kong, where he was discovered by auteur director Yonfan, who cast him in the lead role of the art-house film Bishonen. He quickly rose to fame with standout performances in films such as Benny Chan's Gen-X Cops, Purple Storm, and City of Glass, the latter earning him his first of five Hong Kong Film Award nominations for Best New Performer.
        
        In 2005, Wu received dual Hong Kong Film Award nominations for Best Actor in One Night in Mongkok and Best Supporting Actor in Jackie Chan's New Police Story, the latter also earning him Taiwan's Golden Horse Award for Best Supporting Actor. He made his directorial debut in 2006 with The Heavenly Kings, a satirical look at the Hong Kong music industry, which won him the Hong Kong Film Award for Best New Director. Wu was again nominated for Best Actor in 2015 for his role in Dante Lam's psychological thriller That Demon Within.
        
        Wu’s international film credits include Around the World in 80 Days (2004), Blood Brothers (2007), Overheard (2009), Quentin Tarantino’s The Man with the Iron Fists (2012), Europa Report (2013), China’s Academy Award submission Go Away Mr. Tumor, Duncan Jones’ Warcraft (2016), Geostorm (2017), and the Tomb Raider remake (2018). On television, he starred in and executive produced AMC’s hit series Into the Badlands (2015–2019). More recently, Wu appeared in Caught in Time (2020), Lisa Joy’s sci-fi noir thriller Reminiscence (2021), and HBO’s Westworld (2022).
        
        In 2023, Wu took on the role of Sun Wukong, the legendary Monkey King, in the Disney+ series American Born Chinese, adapted from Gene Luen Yang’s award-winning graphic novel.
        
        Outside of acting, Wu is a prominent advocate for the Asian American community and other charitable causes. He was honored with the Bruce Lee Award in 2022, presented by the AWFF and the Bruce Lee Foundation, for his contributions to martial arts and their cultural legacy. Additionally, Wu is an accomplished race car driver, competing in the IMSA Michelin Pilot Challenge series.
        
        With a multifaceted career marked by talent and dedication, Daniel Wu has cemented his place as a leading figure in global entertainment and a beloved name in the worldwide Chinese community.`,
        link: "https://www.imdb.com/name/nm0943079/",

        additionalLinks: [
            {
              label: "Daniel Wu Info",
              url: "https://www.rottentomatoes.com/celebrity/daniel_wu"
            },
            {
              label: "Daniel Wu wikipedia",
              url: "https://en.wikipedia.org/wiki/Daniel_Wu"
            }
            ,
            {
              label: "JDaniel Wu story",
              url: "https://maekan.com/story/daniel-wu/"
            }
          ]

    }, {
        id: 5,
        name: "Susan Prior",
        movie: "Just a Farmer",
        title: "Best Supporting Actress for Feature Films",
        imageDir: `${missingImage}`,
        year: 2024,
        biography: `Susan Prior is an accomplished Australian actress known for her work across film, television, and theatre. She is a graduate of the National Institute of Dramatic Art (NIDA) in Sydney and has had an extensive career with the Sydney Theatre Company, appearing in productions such as Riflemind.

        In film, Prior marked her early career with notable roles in Lyndon Johnson (1986-87), Muriel's Wedding (1995), and Idiot Box (1997). She went on to appear in Heaven's Burning (1998), The Saviour (2007), and Suburban Mayhem (2008), where she starred alongside Emily Barclay. Prior continued to earn recognition with her role in Animal Kingdom (2010) alongside Guy Pearce, and in the foreign film Not Suitable for Children (2013) with Ryan Kwanten. In 2014, she acted in the crime drama The Rover with Guy Pearce, a performance that earned her the 2014 AACTA Award for Best Actress in a Supporting Role.

        Susan Prior also garnered critical acclaim for her television work, including a nomination for the 2012 AACTA Award for Best Guest or Supporting Actress in a Television Drama for Puberty Blues. She was twice nominated for the Helpmann Award for Best Female Actor in a Supporting Role in a Play, first in 2008 for Riflemind and again in 2014 for Small and Tired.

        In 2022, Prior earned rave reviews for her portrayal of Martha in the State Theatre Company South Australia's production of Edward Albee's Who’s Afraid of Virginia Woolf?, where she played one of the lead roles.

        With a diverse and celebrated career in both dramatic and supporting roles, Susan Prior has solidified her place as a respected figure in the Australian entertainment industry.
        .`,
        link: "https://www.imdb.com/name/nm0697960/",
        additionalLinks: [
            {
              label: "Susan Prior moviedb",
              url: "https://www.themoviedb.org/person/76069-susan-prior"
            },
            {
              label: "Susan Prior wikipedia",
              url: "https://en.wikipedia.org/wiki/Susan_Prior"
            }
          ]
    }, {
        id: 6,
        name: "Robert Connolly",
        movie: "Force of Nature: The Dry 2",
        title: "Best Screenplay for Feature Films",
        imageDir: `${ForceOfNatureDirector_RobertConnolly}`,
        year: 2024,
        biography: `Robert Connolly (born 1967) is one of Australia’s most renowned filmmakers, celebrated for his multifaceted contributions as a director, producer, and screenwriter. With a career spanning over 30 years, Connolly has consistently delivered works that delve into political, historical, and social themes, earning both local and international acclaim.

        Connolly’s directorial debut came with The Bank (2001), followed by Three Dollars (2005), both of which earned critical recognition and several awards. His breakthrough came with Balibo (2009), a poignant exploration of the politically charged deaths of journalists during the Indonesian invasion of East Timor in 1975. Co-written with David Williamson, the film was the first feature shot in East Timor and remains a cornerstone of politically conscious Australian cinema.
        
        Connolly further cemented his reputation with The Dry (2020), an adaptation of Jane Harper’s best-selling novel starring Eric Bana. The film achieved critical and commercial success, leading to a sequel, Force of Nature: The Dry 2 (2024). Other notable feature films include Paper Planes (2014), Underground: The Julian Assange Story (2012), and Blueback (2022), which screened at the Toronto International Film Festival and Sundance Film Festival. His recent film, The Surfer (2024), premiered at the Cannes Film Festival, showcasing Connolly’s continued relevance in global cinema.
    
        Connolly has left a significant mark on television, directing the International Emmy-nominated series The Slap (2011), based on Christos Tsiolkas’s novel. Other television credits include Barracuda (2016) for NBC Universal and the political thriller Deep State (2018) for Fox Network Group, starring Mark Strong.
        
        Through his production company, Arenamedia, Connolly has championed innovative storytelling and emerging voices. His producing credits include Romulus, My Father (2007), winner of four Australian Film Institute Awards, and The Boys (1998). Arenamedia has also supported First Nations filmmakers with projects like Jub Clerc’s Sweet As and Stephen Page’s Spear. The company has collaborated with iconic talents such as Cate Blanchett, Hugo Weaving, and Rose Byrne on The Turning (2013), and produced Frances O’Connor’s Emily (2022), starring Emma Mackey.
        
        Connolly’s films have screened at over 30 international festivals, including Toronto, San Sebastián, and Sundance. His unique storytelling style blends Hollywood thriller elements with quintessentially Australian themes, resonating with audiences worldwide. His contributions to the Australian film industry were honored with a Centenary Medal in 2001, and he has received numerous AFI Awards for his work.
        
        Matthew Campora, head of screen studies at AFTRS, describes Connolly as one of Australia’s most successful contemporary filmmakers, continuing the rich tradition of Australian cinema established in the 1990s.
        `,
        link: "https://www.imdb.com/name/nm0175352/",
        additionalLinks: [
            {
              label: "Robert Connolly wikipedia",
              url: "https://en.wikipedia.org/wiki/Robert_Connolly"
            },
            
          ]
        
    }, {
        id: 7,
        name: "Gavin Head",
        movie: "Just a Farmer",
        title: "Best Cinematography for Feature Films",
        imageDir: `${missingImage}`,
        year: 2024,
        biography: "Gavin Head, acclaimed for his work in Just a Farmer (2024), Gurrumul (2017), and Hotel Mumbai (2018), received the Best Cinematography for a Feature Film award at the 2024 International Film Festival of Australia Awards for Just a Farmer. His talent and artistry continue to make a significant impact on the film industry.",
        link: "https://www.imdb.com/name/nm1153659/"
    }, {
        id: 3,
        name: "Dev Patel",
        movie: "Monkey Man",
        title: "Best Director for Feature Films",
        imageDir: `${missingImage}`,
        year: 2024,
        biography: `Dev Patel is a British actor and filmmaker, born on April 23, 1990, in Harrow, London, to Gujarati-Indian parents originally from Nairobi, Kenya. His mother, Anita, was a caregiver, and his father, Raj, worked in IT. Patel's acting career began with the role of Anwar Kharral in the popular teen drama Skins (2007), which led to his breakout role as Jamal Malik in Slumdog Millionaire (2008). This performance earned him a BAFTA nomination for Best Actor and was a major turning point in his career.

        Following Slumdog Millionaire, Patel starred in a variety of films, including The Best Exotic Marigold Hotel (2011) and its sequel The Second Best Exotic Marigold Hotel (2015), Chappie (2015), and Hotel Mumbai (2018). His portrayal of Saroo Brierley in Lion (2016) garnered critical acclaim, earning him nominations for the Academy Award and winning the BAFTA for Best Supporting Actor.

        Patel also appeared in the independent films The Personal History of David Copperfield (2019) and The Green Knight (2021), while also starring in the TV series The Newsroom (2012-2014). In 2024, he made his directorial debut with Monkey Man, an action thriller in which he also starred, wrote, and produced. The film had its premiere at South by Southwest and was released in cinemas globally.

        In addition to his acting and directing work, Patel has been recognized for his influence, being included in Time’s list of the 100 most influential people in 2024. He continues to build an impressive career, with upcoming projects like the miniseries The Key Man and the film Wicker alongside Olivia Colman.`,
        link: "https://www.imdb.com/name/nm2353862/",
        additionalLinks: [
            {
              label: "Dev Patel Info",
              url: "https://www.rottentomatoes.com/celebrity/dev_patel/"
            },
            {
              label: "Dev Patel wikipedia",
              url: "https://en.wikipedia.org/wiki/Dev_Patel"
            }
          ]

    },{
        id: 15,
        name: "Morteza Halimi",
        movie: "A Robot's Dream",
        title: "Best Animation",
        imageDir: `${RobotDirector}`,
        year: 2024,
        biography: `Currently practicing as an architect in Australia, Morteza has been telling stories through film and animation since a young age, using these mediums as a creative outlet to explore and express his ideas. His short projects have been showcased both nationally and internationally.

        Passionate about the constantly evolving technologies in film and animation—most recently artificial intelligence—Morteza uses storytelling to delve into the ways these innovations influence our perceptions and connections. His work examines how these technologies reflect our humanity, shaping the way we create, communicate, and understand ourselves.`,
        link: "https://www.instagram.com/more_tezza/"
    }, {
        id: 14,
        name: "Takaya Onishi",
        movie: "Joy of Art",
        title: "Best Documentary",
        imageDir: `${JoyOfArtDirector}`,
        year: 2024,
        biography: `1987 Born in Kakogawa City, Hyogo Prefecture
        
        Starting his journey in 2006, he entered the Faculty of Engineering at Okayama University, where he graduated in 2011. In 2008, he formed the comedy duo "Link Up" and went on to complete several film projects, including the short films Mahoroba (2015) and Iwamoto has one month left to live (2016).

        By 2017, he completed his feature film Costume Master and founded STUDIO TONOLION in 2018. His career progressed with notable milestones, including being appointed as the representative director of the Filmmaker Support Organization in 2020 and establishing a joint venture in 2021. He continued to produce impactful work, including the short film Owl of Minerva -Requiem (2021) and the feature film Owl of Minerva (2022). In 2022, he was appointed as the representative of the Children's Film Club and later obtained qualifications as a nursery teacher in 2023.

        In 2024, he completed the documentary Joy of Art and earned multiple awards and nominations, including recognition at the Miyakojima Charity International Film Festival, LANE DOC FEST, and the International Film Festival of Australia.`,
        link: "https://tonolion.jp/bae34-rokophoto-about/"
    }, {
        id: 16,
        name: "Harrison J Thomas",
        movie: "Melody (7 min cut)",
        title: "Best Under 18 Film",
        imageDir: `${MelodyDirector}`,
        year: 2024,
        biography: `Harrison is a 13-year-old, award-winning actor, filmmaker, musician, and vocalist. He has written, directed, produced, edited, and scored five short films to date, which have won numerous awards at both national and international festivals. Some of these festivals include Ridgewood International Film Festival (New Jersey), Adelaide Film Festival, Fleurieu Film Festival, Sparrowland, Setting Sun, San Antonio, Moscow, Prague, and many more.

        Harrison has twice won the Young Filmmaker Award at the Sparrowland Short Film Festival (Queensland) and was named Best Young Filmmaker Under 26 at the Fleurieu Film Festival (South Australia). He’s unique in that he handles all aspects of his post-production work on his own.

        Beyond filmmaking, Harrison is a dedicated performer, having starred in over 30 stage productions since 2020. These include professional performances at Her Majesty’s Theatre, the Adelaide Fringe, and the Royal Show, as well as amateur productions with Adelaide University's Elder Conservatorium, Pelican Productions, Adelaide Youth Theatre, Northern Light, and Shane Davidson Productions.

        Some of his notable roles include Young Elvis in the Adelaide season of Elvis: A Musical Revolution (David Venn Enterprises), and the role of Rapskullio in Between the Lines Jr (New York producers, Junior Theatre Festival Australia 2023). In 2024, Harrison will play Little Sally in Prince Alfred College's Urinetown and Jack Kelly in Newsies Jr with Pelican Productions.

        Harrison also produces under his own label, Fastest Fox Films, and enjoys writing songs just as much as he enjoys writing scripts. He often has to be “wrestled” from his piano and guitar at bedtime!`,
        link: "https://www.instagram.com/harrison_j_thomas/",
        additionalLinks: [
            {
              label: "Harrison J Thomas Info",
              url: "https://filmfreeway.com/HarrisonJThomas"
            },
            {
              label: "Harrison J Thomas LinkedIn",
              url: "https://www.linkedin.com/in/harrison-j-thomas-4878a52b4/"
            }
          ]

    }, {
        id: 17,
        name: "JJ Pantano",
        movie: "Unsung Hero",
        title: "Best Emerging Child Actor",
        imageDir: `${UnsungHeroActor_JJPantano}`,
        year: 2024,
        biography: `JJ Pantano is a gifted 12-year-old actor, comedian, and musician who rose to fame at just seven years old with his roast-style comedic act on Australia's Got Talent. His quick wit and fearless stage presence earned him the prestigious Golden Buzzer from judge Shane Jacobson. The viral success of his performance led to an invitation to America's Got Talent: The Champions Season 2, where he impressed audiences and judges, including Simon Cowell and Howie Mandel, with his comedic style and earned a spot in the semi-finals. Howie Mandel even compared JJ to comedy legend Don Rickles, while Simon Cowell praised his "star glow" and fearless approach.

        Following his success on AGT, JJ was invited back to host a segment on the Champions Results Show, where he interviewed the judges in a Between Two Ferns-style format. This recognition helped open doors to mentorship with Howie Mandel and meetings with casting directors from Nickelodeon, Disney, and other major entertainment companies.

        In 2023, JJ performed alongside Jeff Ross at Melbourne's Rod Laver Arena and returned a year later to roast Jeff Ross at the Netflix Is A Joke Roast Battle Grand Finals in Hollywood. His roast performances have captivated audiences worldwide, amassing over 200 million views across his Got Talent appearances.

        In addition to his comedy career, JJ is an emerging actor, starring as young Luke Smallbone in Unsung Hero, a Lionsgate film about the Smallbone family's journey to producing Grammy-winning artists. The film achieved a 99% Rotten Tomatoes score and debuted at No. 2 at the U.S. box office. JJ also appears in the Australian comedy Nut Farm (available on Amazon Prime) and Wog Boys Forever (Paramount+), where he plays Mario Jr.

        JJ's talent extends beyond comedy and acting. He is also a singer and musician, having released his debut single They Just Don’t Get It, with a second single on the way. JJ’s hobbies include athletics, soccer, video games, hoverboarding, and skiing.

        With a U.S. O1 Visa for extraordinary talent and dual citizenship in Australia and Italy, JJ is poised for an incredible future in entertainment. His boundless energy, professionalism, and ever-growing fan base are proof of his exceptional talent and promising career ahead.`,
        link: "https://www.instagram.com/jjpantano",
        additionalLinks: [
            {
              label: "JJ Pantano Website",
              url: "https://www.jjpantano.com/bio/"
            },
            {
              label: "JJ Pantano moreInfo",
              url: "https://www.rgm.com.au/entertainment/corporate-speakers/jj-pantano-2/"
            },
            {
              label: "JJ Pantano YouTube",
              url: "https://www.youtube.com/c/JJPantano/featured"
            },
            {
              label: "JJ Pantano IMBD",
              url: "https://www.imdb.com/name/nm9888813/bio/?ref_=nm_ov_bio_sm"
            }
          ]

    }

]