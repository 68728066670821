// import { motion } from "motion/react";
// import { Link } from "react-router-dom";
// import { TitleSVG } from "../../../Data/export_images";
import { MonkeyManTrailerCropped } from "../../../Data/export_videos";
import Navbar from "../../Navbar/Navbar";
import { HeroSectionProps } from "./HeroSection";

const DesktopHeroSection = ({videoBackground} : HeroSectionProps) => {
    return (
        <div className='relative max-sm:hidden ~lg/2xl ~h-[400px]/[1204px]'>
            {/* <Navbar /> */}
            <Navbar isHomePage/>
            <video
                autoPlay
                loop
                muted
                className="w-full ~md/xl ~h-[400px]/[1204px] object-fill max-xl:object-fit"
            >
                <source src={videoBackground} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className='absolute inset-0 bg-black opacity-30'/>
            {/* <div className="absolute inset-0 xl:top-20 max-xl:top-28 max-[1100px]:top-40 max-lg:top-28 flex flex-col justify-between p-20 xl:p-40 xl:pb-20"> */}
                {/* <div className="flex flex-col">
                    <motion.img
                        src={TitleSVG}
                        alt="Title"
                        initial="hidden"
                        animate="visible"
                        // variants={floatVariants}
                        whileHover={{ scale: 1.1 }}
                        style={{ willChange: "transform" }} // Ensure smooth rendering
                        className="w-[300px] xl:w-[400px]"
                    />
                </div> */}
                {/* <div className="flex flex-col items-end text-white text-3xl xl:text-6xl font-bold">
                    <h1>5</h1>
                    <h1>NOVEMBER</h1>
                    <h1>2024</h1>
                </div> */}
            {/* </div> */}
        </div>
    )
}

export default DesktopHeroSection;