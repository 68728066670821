import { motion } from "motion/react"
import { Link } from "react-router-dom"

const SubmitWorkButton = () => {
    const submissionLink = "https://filmfreeway.com/iffa-awards?tpclid=facebook.PAZXh0bgNhZW0CMTEAAabJeQpWqtNQoaQyng5NgUKY924NJ4lZXs8zxwIkFKDRGb-A8_Q4MsSGpGg_aem_rRIoYWLW6NfghTFaAgz4Zw";

    return (
        <div className="max-sm:hidden">
            <motion.div
                initial="hidden"
                animate="visible"
                whileHover={{scale: 0.95}}
                transition={{type: 'spring', stiffness: 300}}
                className="flex items-center justify-center w-fit px-8 py-4 xl:w-64 rounded-xl bg-transparent text-light-gold font-bold text-xl transition-all duration-300 ease-in-out hover:scale-98  hover:shadow-light-gold hover:shadow-lg"
                style={{willChange: "transform"}}
            >
                <Link
                    to={submissionLink}
                    className="xl:text-2xl lg:text-xl"
                >
                    Submit Movie
                </Link>
            </motion.div>
        </div>
    )
}

export default SubmitWorkButton;