const getMovieVideoPath = (imagePath: string) => {
    return process.env.PUBLIC_URL + 'videos/' + `${imagePath}`;
}

const MonkeyManTrailer = getMovieVideoPath('MonkeyManTrailer.mp4');
const MonkeyManTrailerCropped = getMovieVideoPath('MonkeyManTrailer_cut.mp4');
const DriveTrailer = getMovieVideoPath('DriveTrailer.mp4');
const LoveLiesBleedingTrailer = getMovieVideoPath('LoveLiesBleedingTrailer.webm');
const IFFAVideo = getMovieVideoPath('IFFAVideo.mp4');

export {
    MonkeyManTrailer,
    MonkeyManTrailerCropped,
    DriveTrailer,
    LoveLiesBleedingTrailer,
    IFFAVideo
}