import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from '../Components/Footer/Footer';
import { MovieCast, createMoviesCastList } from '../Data/casts'; 
import casts from '../Data/casts';
import { missingImage } from "../Data/export_images";

function CastDetail() {
    const location = useLocation();
    const { castMember, otherCastAndCrew = [], movieName } = (location.state || {
        castMember: {} as MovieCast,
        otherCastAndCrew: [] as MovieCast[],
        movieName: ""
    }) as {
        castMember: MovieCast;
        otherCastAndCrew: MovieCast[];
        movieName: string;
    };
    
    const navigate = useNavigate();

    const allCastAndCrew = [
        {
            castName: castMember.castName,
            roles: castMember.roles,
            image: castMember.image,
            bio: castMember.bio,
        },
        ...otherCastAndCrew.map(other => ({
            castName: other.castName,
            roles: other.roles,
            image: other.image,
            bio: other.bio,
        }))
    ];

    const handleCastClick = (selectedCastMember: Partial<MovieCast>) => {
        const movieCastList = createMoviesCastList(casts);
        const currentMovie = movieCastList.find(movie => movie.movieName.trim().toLowerCase() === movieName.trim().toLowerCase());

        if (currentMovie) {
            const newOtherCastAndCrew = currentMovie.casts.filter( (member) => member.castName !== selectedCastMember.castName);
            navigate("/cast", { state: { castMember: selectedCastMember, otherCastAndCrew: newOtherCastAndCrew, movieName } });
        } else {
            console.warn("Movie not found or no casts available");
        }
    };

    return (
        <div>
            <div className="flex flex-col items-center bg-black w-full px-4 md:px-8 py-12 text-white">
                <div className="flex flex-col md:flex-row items-center w-full mb-4">
                    <img src={castMember.image || missingImage} alt={`${castMember.castName}`} className="h-full object-cover w-full md:w-1/2 lg:w-1/3" />
                    <div className="flex flex-col items-start w-full mt-6 md:mt-0 md:ml-12">
                        <h1 className="text-white text-2xl sm:text-3xl md:text-4xl font-bold">{castMember.castName}</h1>
                        <div className="text-gray-500 text-lg sm:text-xl">
                            <p>{Array.isArray(castMember.roles) ? castMember.roles.join(" | ") : castMember.roles}</p>
                        </div>
                        <blockquote className="text-white text-lg sm:text-xl mt-8 mr-32">" {castMember.quote || "No quote available"} "</blockquote>
                        <div className="flex space-x-4 mt-2">
                            {castMember.socialLinks?.facebook && (
                                <a href={castMember.socialLinks.facebook} className="text-white hover:text-gray-500">
                                    <i className="fab fa-facebook"></i>
                                </a>
                            )}
                            {castMember.socialLinks?.twitter && (
                                <a href={castMember.socialLinks.twitter} className="text-white hover:text-gray-500">
                                    <i className="fab fa-twitter"></i>
                                </a>
                            )}
                        </div>
                    </div>
                </div>

                <h2 className="text-white text-2xl sm:text-3xl font-bold mt-16">Bio of this artist</h2>
                <div className="p-4 mt-2 mx-4 md:mx-32 lg:mx-96">
                    {castMember.bio && typeof castMember.bio == 'string' ? (
                        castMember.bio.startsWith('http') ? (
                            <a href={castMember.bio} target="_blank" rel="noopener noreferrer" className="text-blue-400 underline">
                                Visit Bio
                            </a>
                        ) : (
                            <p className="text-white">{castMember.bio}</p>
                        )
                    ) : (
                        <p className="text-white">No biography available</p>
                    )}
                </div>

                <h2 className="text-white text-2xl sm:text-3xl font-bold mt-16">Other Cast & Crew</h2>
                <div className="flex flex-wrap justify-center space-x-0 md:space-x-4 py-4">
                    {allCastAndCrew
                        .filter((person) => person.castName !== castMember.castName)
                        .map((person, index) => (
                            <div
                                key={index}
                                className="flex flex-col items-center m-4 cursor-pointer w-full sm:w-auto"
                                onClick={() => handleCastClick(person)}
                            >
                                <img src={person.image || missingImage} alt={person.castName} loading="lazy" className="w-24 h-24 object-cover rounded-full" />
                                <p className="text-white mt-2">{person.castName}</p>
                                <div className="text-gray-500">
                                    {Array.isArray(person.roles) ? (
                                        person.roles.map((role, roleIndex) => (
                                            <p key={roleIndex}>{role}</p>
                                        ))
                                    ) : (
                                        person?.roles?.split(',').map((role, roleIndex) => (
                                            <p key={roleIndex}>{role.trim()}</p>
                                        ))
                                    )}
                                </div>
                            </div>
                        ))}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default CastDetail;
